import React from "react"
import BackgroundImage from "gatsby-background-image"
import { renderAst } from "../ast/renderAst"
import { Link } from "gatsby"

const HomeHero = ({ data }) => {
  const {
    backgroundImage: { fluid },
    headline,
    subheadline,
    text: {
      childMarkdownRemark: { htmlAst: text },
    },
  } = data[0]

  return (
    <>
      <BackgroundImage tag="div" fluid={fluid} fadeIn={false} loading="eager">
        <div
          style={{
            position: "absolute",
            backgroundColor: "black",
            opacity: 0.5,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <div className="container mx-auto relative text-white text-center px-6 pt-12 pb-16 lg:bx-12 lg:pt-40 lg:pb-48">
          <div>
            <h1 className="text-3xl leading-tight font-bold capitalize mb-2 md:text-5xl">
              {headline}
            </h1>
            <h2 className="text-xl leading-tight font-bold mb-10 md:text-2xl">
              {subheadline}
            </h2>
            {renderAst(
              {
                a: ({ children, href }) => {
                  if (href === "reviews") {
                    return (
                      <div className="inline-block mb-6 md:mb-0 select-none uppercase bg-gray-700 text-xl text-white font-bold mx-3 py-3 px-4 rounded-sm opacity-75">
                        {children}
                      </div>
                    )
                  } else {
                    return (
                      <Link
                        className="inline-block mb-6 md:mb-0 uppercase bg-brand hover:bg-brand-lighter text-xl text-white font-bold mx-3 py-3 px-4 rounded-sm"
                        to={`/${href}/`}
                      >
                        {children}
                      </Link>
                    )
                  }
                },
              },
              text
            )}
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeHero
