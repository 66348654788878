import React, { Fragment } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeBody from "../components/home/homeBody"
import HomeHero from "../components/home/homeHero"

export default ({
  data: {
    contentfulLayoutTemplate: {
      title,
      shortDescription,
      seoTitle,
      slug,
      canonical,
      blocks,
    },
  },
  location,
}) => {
  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
      />
      {blocks !== null &&
        blocks.map((block) => {
          return (
            <Fragment key={block.id}>
              {block.__typename === "ContentfulBlock" &&
                block.title === "Home > Hero" && (
                  <HomeHero data={block.components} />
                )}
              {block.__typename === "ContentfulBlock" &&
                block.title === "Home > Body" && (
                  <HomeBody data={block.components} />
                )}
            </Fragment>
          )
        })}
    </Layout>
  )
}

export const homePageQuery = graphql`
  query {
    contentfulLayoutTemplate(title: { eq: "Home" }) {
      id
      title
      slug
      canonical
      seoTitle
      shortDescription
      type
      blocks {
        __typename
        id
        title
        components {
          ... on Node {
            __typename
            id
            ... on ContentfulHeroComponent {
              id
              title
              headline
              subheadline
              backgroundImage {
                id
                description
                fluid(maxWidth: 1200) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              text {
                childMarkdownRemark {
                  htmlAst
                }
              }
            }
            ... on ContentfulContentComponent {
              id
              title
              text {
                childMarkdownRemark {
                  htmlAst
                }
              }
            }
          }
        }
      }
    }
  }
`
