import React from "react"

import { renderAst } from "../ast/renderAst"
import ContentAst from "../content/contentAst"

const HomeBody = ({ data }) => {
  const {
    text: {
      childMarkdownRemark: { htmlAst: text },
    },
  } = data[0]

  const bodyLength = text.children.length
  const halfway = Math.ceil(bodyLength / 2)
  const column1 = {
    type: "root",
    data: { quirksMode: false },
    children: text.children.slice(0, halfway),
  }
  const column2 = {
    type: "root",
    data: { quirksMode: false },
    children: text.children.slice(halfway, bodyLength),
  }

  const renderColumn = (column) => {
    return renderAst(ContentAst, column)
  }

  return (
    <>
      <div className="container mx-auto p-6">
        <div className="flex flex-wrap">
          <div className="prose prose-lg w-full md:pr-6 md:w-1/2">{renderColumn(column1)}</div>
          <div className="prose prose-lg w-full md:pl-6 md:w-1/2">{renderColumn(column2)}</div>
        </div>
      </div>
    </>
  )
}

export default HomeBody
